import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Map from '@eeacms/volto-openlayers-map/Map';
import { Interactions } from '@eeacms/volto-openlayers-map/Interactions';
import { Controls } from '@eeacms/volto-openlayers-map/Controls';
import { Layers, Layer } from '@eeacms/volto-openlayers-map/Layers';
import { Overlays } from '@eeacms/volto-openlayers-map/Overlays';
import { openlayers } from '@eeacms/volto-openlayers-map';
import Popup from './Popup';

const View = ({ events }) => {
  const [eventFeatures, setEventFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureProperties, setSelectedFeatureProperties] = useState(
    [],
  );
  const [tileWMSSources, setTileWMSSources] = useState([]);

  const overlayPopup = useRef(null);
  const { style, proj } = openlayers;

  // Style for the map marker
  const markerStyle = new style.Style({
    image: new style.Circle({
      radius: 7,
      fill: new style.Fill({ color: 'rgba(255, 0, 0, 1)' }),
      stroke: new style.Stroke({ color: 'white', width: 5 }),
    }),
  });

  const fetchCoordinates = async (cityName) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?city=${cityName}&format=json`,
      );
      const data = await response.json();
      if (data.length > 0) {
        return [parseFloat(data[0].lon), parseFloat(data[0].lat)];
      }
    } catch (error) {
      console.error('Error fetching coordinates', error);
    }
    return [];
  };

  // Load coordinates for each event and set map features
  useEffect(() => {
    const loadEventFeatures = async () => {
      const features = await Promise.all(
        events.map(async (event) => {
          if (!event.city) return null;
          const coords = await fetchCoordinates(event.city);
          // Only return a feature if coords are valid
          if (coords && coords.length > 0) {
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: proj.fromLonLat(coords),
              },
              properties: {
                name: event.name || '',
                description: event.description || '',
                date: event.startDate || '',
                linkToEvent: event.linkToEvent,
              },
            };
          }
          return null;
        }),
      );

      setEventFeatures(features.filter(Boolean)); // Filter out null entries if any
    };

    loadEventFeatures();
  }, [events]);

  const geojsonObject = {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:3857',
      },
    },
    features: eventFeatures,
  };

  const source = new openlayers.source.Vector({
    features: new openlayers.format.GeoJSON().readFeatures(geojsonObject),
  });

  // Handle click events on the map
  const onClickEvent = (e) => {
    const pixelTolerance = 10;
    let clickedFeature = null;
    e.map.forEachFeatureAtPixel(
      e.pixel,
      (feature) => {
        clickedFeature = feature;
        return true; // Stop after first found feature
      },
      { hitTolerance: pixelTolerance },
    );

    if (clickedFeature) {
      const featureProperties = clickedFeature.getProperties();
      setSelectedFeature(clickedFeature);
      setSelectedFeatureProperties(featureProperties);
      overlayPopup.current.setPosition(e.coordinate);
    } else {
      setSelectedFeature(null);
      setSelectedFeatureProperties(null);
      overlayPopup.current.setPosition(undefined);
    }
  };

  useEffect(() => {
    if (__SERVER__) return;
    setTileWMSSources([
      new openlayers.source.TileWMS({
        url: 'https://gisco-services.ec.europa.eu/maps/service',
        params: {
          LAYERS: 'OSMBlossomComposite',
          TILED: true,
        },
        serverType: 'geoserver',
        transition: 0,
      }),
    ]);
  }, []);

  return (
    <div className="events-map-wrapper">
      <div className="events-map" id="events-map">
        <Map
          view={{
            center: proj.fromLonLat([10, 50]),
            zoom: 5,
          }}
          pixelRatio={1}
          onClick={onClickEvent}
        >
          <Layers>
            {tileWMSSources && (
              <Layer.Tile source={tileWMSSources[0]} zIndex={0} />
            )}
            <Layer.Vector
              source={source}
              style={() => markerStyle}
              zIndex={1}
            />
          </Layers>
          <Overlays
            ref={(overlayComponent) => {
              overlayPopup.current = overlayComponent?.overlay;
            }}
            positioning="bottom-center"
            stopEvent={true}
          >
            {selectedFeature && (
              <Popup data={selectedFeatureProperties} overlay={overlayPopup} />
            )}
          </Overlays>
          <Controls attribution={true} zoom={true} />
          <Interactions pointer={true} select={true} />
        </Map>
      </div>
    </div>
  );
};

View.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
};

export default View;
