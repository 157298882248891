import React from 'react';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import logo from './logo/MoEDS-logo-72.png';
import fbIcon from './social-media/fb-icon.png';
/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = () => {
  return (
    <footer id="footer">
      <Container className="footer">
        <div className="footer-row">
          <img className="logo" src={logo} alt="stdk-logo" />
          <div>
            <div className="footer-text">
              Pushing the boundaries, expressing yourself, working with others
              applying critical thinking and turning new learning into
              innovative solutions.
              <h5 className="footer-name">Study in Denmark</h5>
            </div>
          </div>
        </div>
        <div className="footer-separator"></div>
        <div className="footer-row">
          <div></div>
          <ol>
            <li>
              <a href="http://www.ufm.dk/en">
                Studyindenmark.dk is managed by the Danish Ministry of Higher
                Education and Science
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/studyindk/">Contact</a>
            </li>
            <li>
              <a href="https://www.facebook.com/studyindk/">
                Find us on social media{' '}
                <span>
                  <img
                    className="social-media"
                    src={fbIcon}
                    alt="socialmedia"
                  />
                </span>
              </a>
            </li>
            <li>
              <a href="https://studyindenmark.dk/privacy">
                Disclaimer on cookies
              </a>
            </li>
            <li>
              <a href="https://studyindenmark.dk/was">
                Tilgængelighedserklæringen
              </a>
            </li>
            <li>
              <a href="http://www.ufm.dk">www.ufm.dk</a>
            </li>
          </ol>
        </div>
      </Container>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default Footer;
