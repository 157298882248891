import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import ArrowDownSvg from './ArrowDown';

const NavItem = ({ item, lang, depth = 0 }) => {
  const { settings } = config;
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  if (isInternalURL(item.url) || item.url === '') {
    return (
      <>
        <div
          className={'nav-item'}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
          onClick={handleLeave}
          key={item.url}
        >
          <div>
            <NavLink
              to={item.url === '' ? '/' : item.url}
              className={`item ${
                depth === 0 ? 'dropdown-item' : 'nested-dropdown-item'
              }`}
              activeClassName="active"
              exact={
                settings.isMultilingual
                  ? item.url === `/${toBackendLang(lang)}`
                  : item.url === ''
              }
            >
              {item.title}
            </NavLink>
          </div>
          {item.items.length && depth !== 0 ? <ArrowDownSvg /> : ''}
          {isHovered && (
            <div className={`${depth === 0 ? 'dropdown' : 'nested-dropdown'}`}>
              {item.items.map((subItem, index) => (
                <div
                  className={`${
                    depth === 0 ? 'dropdown-item' : 'nested-dropdown-item'
                  }`}
                  key={item.url + index}
                >
                  <NavItem
                    item={subItem}
                    lang={lang}
                    key={item.url + index}
                    depth={depth + 1} // Pass down depth + 1 for nested items
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <a
        href={item.url}
        key={item.url}
        className="item"
        rel="noopener noreferrer"
        target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
      >
        {item.title}
      </a>
    );
  }
};

export default NavItem;
