import React from 'react';
import NavItem from './NavItem';
import NavItemMobile from './NavItemMobile';
const NavItems = ({ items, lang, isMobileMenuOpen, closeMobileMenu }) => {
  return (
    <>
      {isMobileMenuOpen
        ? items.map((item) => (
            <NavItemMobile
              item={item}
              lang={lang}
              isMobileMenuOpen={isMobileMenuOpen}
              closeMobileMenu={closeMobileMenu}
              key={item.url}
            />
          ))
        : items.map((item) => (
            <NavItem item={item} lang={lang} key={item.url} depth={0} />
          ))}
    </>
  );
};

export default NavItems;
