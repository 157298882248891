import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import ArrowDownSvg from './ArrowDown';
import ArrowUpSvg from './ArrowUp';

const NavItemMobile = ({ item, lang, closeMobileMenu, depth = 0 }) => {
  const { settings } = config;
  const [isOpened, setIsOpened] = useState(false);

  const handleOpen = () => {
    setIsOpened(true);
  };
  const handleClose = () => {
    setIsOpened(false);
  };

  if (depth > 1) return '';
  if (isInternalURL(item.url) || item.url === '') {
    return (
      <div>
        <div
          className={`${
            depth === 0 ? 'nav-item-mobile' : 'nav-item-mobile-nested'
          }`}
        >
          <div className="nav-link-wrapper">
            <NavLink
              to={item.url === '' ? '/' : item.url}
              className={`item ${
                depth === 0 ? 'dropdown-item' : 'nested-dropdown-item'
              }`}
              activeClassName="active"
              exact={
                settings.isMultilingual
                  ? item.url === `/${toBackendLang(lang)}`
                  : item.url === ''
              }
              onClick={closeMobileMenu}
            >
              {item.title}
            </NavLink>
          </div>
          {item.items.length && isOpened && depth == 0 ? (
            <div className="arrow-up-wrapper" onClick={handleClose}>
              <ArrowDownSvg />
            </div>
          ) : item.items.length && !isOpened && depth == 0 ? (
            <div className="arrow-up-wrapper" onClick={handleOpen}>
              <ArrowUpSvg />
            </div>
          ) : (
            ''
          )}
        </div>

        <div
          className={`${
            depth === 0 && isOpened
              ? 'mobile-dropdown-active'
              : 'mobile-dropdown'
          }`}
        >
          {item.items.map((subItem, index) => (
            <div
              className={`${
                depth === 0 ? 'dropdown-item' : 'nested-dropdown-item'
              }`}
              key={subItem.url + index}
            >
              <NavItemMobile
                item={subItem}
                lang={lang}
                closeMobileMenu={closeMobileMenu}
                depth={depth + 1}
              />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <a
        href={item.url}
        key={item.url}
        className="item"
        rel="noopener noreferrer"
        target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
      >
        {item.title}
      </a>
    );
  }
};

export default NavItemMobile;
