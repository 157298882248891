import { useSelector } from 'react-redux';

function toLocaleString(date = '', locale) {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}
const ProgrammeDatesView = ({ value }) => {
  const locale = useSelector((state) => state.intl?.locale);

  return value?.data ? (
    <section className="program-dates well">
      {value.data.map((item, index) => {
        let endDate = new Date(item.deadline);
        let startDate = new Date(item.start);
        let deadline = toLocaleString(endDate, locale);
        let start = toLocaleString(startDate, locale);

        return (
          <div className="program-container" key={item['@id'] ?? index}>
            <div className="program-title">
              <h4>{item.title}</h4>
            </div>
            <div style={{ padding: '1rem' }}>
              <div className="dates-container">
                <h5>Application deadline:</h5>
                {item.deadline && <span>{deadline}</span>}
              </div>
              <div className="dates-container">
                <h5>Start:</h5>
                {item.start && <span>{start}</span>}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  ) : (
    ''
  );
};
export default ProgrammeDatesView;
