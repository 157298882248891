/**
 * EventView view component.
 * @module components/theme/View/EventView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { hasBlocksData, flattenHTMLToAppURL } from '@plone/volto/helpers';
import { Image, Grid, Container as SemanticContainer } from 'semantic-ui-react';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

import { FormattedDate } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { FormattedMessage, injectIntl } from 'react-intl';

const EventTextfieldView = ({ content }) => (
  <React.Fragment>
    {content.title && <h1 className="documentFirstHeading">{content.title}</h1>}
    {content.description && (
      <p className="documentDescription">{content.description}</p>
    )}
    {content.image && (
      <Image
        className="document-image"
        src={content.image.scales.thumb.download}
        floated="right"
      />
    )}
    {content.text && (
      <div
        dangerouslySetInnerHTML={{
          __html: flattenHTMLToAppURL(content.text.data),
        }}
      />
    )}
  </React.Fragment>
);

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventView = (props) => {
  const { content } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return (
    <Container id="page-document" className="view-wrapper event-view">
      {hasBlocksData(content) ? (
        <>
          <RenderBlocks
            {...props}
            content={{
              ...content,
              blocks_layout: {
                items: props.content.blocks_layout.items.slice(0, 1),
              },
            }}
          />
          <h3 className="event-description">{content.description}</h3>
          <Grid stackable className="details-container">
            <Grid.Row columns={2}>
              <Grid.Column>
                <div className="event-detail">
                  <span className="event-header event-border-top">
                    <FormattedMessage id="When" defaultMessage="When" />
                  </span>
                  <span className="event-data">
                    <FormattedDate date={content?.start} format={dateOptions} />{' '}
                    to{' '}
                    <FormattedDate date={content?.end} format={dateOptions} />
                  </span>
                  <span className="event-header">
                    <FormattedMessage id="Where" defaultMessage="Where" />
                  </span>
                  {content?.location && (
                    <span className="event-data event-data-gray">
                      {content?.location}
                    </span>
                  )}
                  <span className="event-header event-border-bottom">
                    <FormattedMessage
                      id="Add-event-to-calendar"
                      defaultMessage="Add event to calendar"
                    />
                  </span>
                  <span>
                    <span className="event-data">
                      <button>vCal</button>
                      <button>iCal</button>
                    </span>
                  </span>
                </div>
                <div>
                  {content?.event_url && (
                    <div className="event-title">
                      <div>
                        <UniversalLink
                          className="event-url"
                          href={content.event_url}
                        >
                          More information about this event...
                        </UniversalLink>
                      </div>
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <RenderBlocks
            {...props}
            content={{
              ...content,
              blocks_layout: {
                items: props.content.blocks_layout.items.slice(1),
              },
            }}
          />
        </>
      ) : (
        <EventTextfieldView {...props} />
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    attendees: PropTypes.arrayOf(PropTypes.string).isRequired,
    contact_email: PropTypes.string,
    contact_name: PropTypes.string,
    contact_phone: PropTypes.string,
    end: PropTypes.string.isRequired,
    event_url: PropTypes.string,
    location: PropTypes.string,
    open_end: PropTypes.bool,
    recurrence: PropTypes.any,
    start: PropTypes.string.isRequired,
    subjects: PropTypes.arrayOf(PropTypes.string).isRequired,
    whole_day: PropTypes.bool,
  }).isRequired,
};
export default injectIntl(EventView);
