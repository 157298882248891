import React from 'react';
import './popup.less';

function Popup(props) {
  const { data } = props;

  const parseDate = (inputDate) => {
    const date = new Date(inputDate);
    return {
      month: monthNames[date.getMonth()],
      day: date.getDate(),
      year: date.getFullYear(),
      time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };
  };

  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const { month, day, year, time } = parseDate(data?.date);

  return (
    <div id="popup">
      <div class="popup-container">
        <div class="popup-date">
          <span class="popup-month">{month || ''}</span>
          <span class="popup-day">{day || ''}</span>
        </div>
        <div class="popup-content">
          <a href={data.linkToEvent} class="popup-title">
            <span class="popup-bullet">&#9679;</span> {data?.name}
          </a>
          <div class="popup-timestamp">
            {month} {day}, {year} {time}
          </div>
          <div class="popup-description">{data?.description}</div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
