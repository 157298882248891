import { applyPlausibleConfig } from 'volto-plausible';
import CarouselTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/CarouselTemplate';
import EventsGrid from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/EventsGrid';
import GridTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/GridTemplate';
import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';
import { composeSchema } from '@plone/volto/helpers';

import NextCloudVideoEdit from './components/volto-hero-video/NextCloudVideoEdit';
import NextCloudVideoView from './components/volto-hero-video/NextCloudVideoView';
import LayoutSchema from './components/volto-hero-video/LayoutSchema';
import { ProgrammeDates, ProgrammeDatesView } from './components/Widgets';

import videoSVG from '@plone/volto/icons/videocamera.svg';

import { defineMessages, createIntlCache, createIntl } from 'react-intl';

const messages = defineMessages({
  NextCloudVideoTitle: {
    id: 'NextCloudVideo',
    defaultMessage: 'NextCloudVideo',
  },
});

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en',
    messages: messages,
  },
  cache,
);

const BG_COLORS = [{ name: 'transparent', label: 'Transparent' }];

const applyConfig = (config) => {
  config.blocks.blocksConfig.nextCloudVideo = {
    ...config.blocks.blocksConfig.nextCloudVideo,
    id: 'nextCloudVideo',
    title: 'Video (NextCloud)',
    icon: videoSVG,
    group: 'media',
    view: NextCloudVideoView,
    subtitlesLanguages: [['en', 'English']],
    edit: NextCloudVideoEdit,
    schema: LayoutSchema(intl),
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
    whiteList: [
      'https://cmshare.eea.europa.eu',
      'https://shareit.eea.europa.eu',
    ],
    security: {
      addPermission: [],
      view: [],
    },
    autoAdd: false,
  };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    colors: BG_COLORS,
    schemaEnhancer: defaultStylingSchema,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'carousel',
        title: 'Carousel',
        template: CarouselTemplate,
      },
    ],
  };
  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      GridTemplate.schemaEnhancer,
    ),
    allowed_headline_tags: [['h2', 'h2']],
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'events_grid',
        title: 'Events Grid',
        template: EventsGrid,
        schemaEnhancer: EventsGrid.schemaEnhancer,
      },
    ],
  };

  config.widgets.id.programme_dates = ProgrammeDates;
  config.widgets.widget.programme_dates = ProgrammeDates;

  if (config.widgets.views?.widget) {
    config.widgets.views.id.programme_dates = ProgrammeDatesView;
    config.widgets.views.widget.programme_dates = ProgrammeDatesView;
  }

  applyPlausibleConfig({ config, domain: 'https://stdk-plone6.edw.ro' });

  return config;
};

export default applyConfig;
