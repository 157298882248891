import React from 'react';

const ArrowUpSvg = () => {
  return (
    <div className="arrow-up-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        className="arrow-up"
      >
        <path
          d="m22.88,13.7l-5.78,5.78l-5.78,-5.78a1,1 0 0 0 -1.42,1.41l6.49,6.49a0.99,0.99 0 0 0 0.71,0.29a1,1 0 0 0 0.71,-0.29l6.49,-6.49a1,1 0 0 0 -1.42,-1.41z"
          id="svg_1"
          transform="rotate(180 17 17.6482)"
        />
      </svg>
    </div>
  );
};

export default ArrowUpSvg;
