import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import EventsMapView from './EventsMap/EventsMapView';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import iconMap from './icon-map.svg';
import iconList from './icon-list.svg';

const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const parseDate = (inputDate) => {
  const date = new Date(inputDate);
  return {
    month: monthNames[date.getMonth()],
    day: date.getDate(),
    year: date.getFullYear(),
    time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
  };
};

const ItemBodyTemplate = ({ item }) => {
  const hasType = item['@type'];
  const CustomItemBodyTemplate = config.getComponent({
    name: 'EventsGrid',
    dependencies: [hasType],
  }).component;

  const truncatedDescription =
    item?.description?.length > 60
      ? item.description.substr(0, 57) + '...'
      : item.description;

  const { month, day, year, time } = parseDate(item.start);

  return CustomItemBodyTemplate ? (
    <CustomItemBodyTemplate item={item} />
  ) : (
    <div className="card-container">
      <div className="event-wrapper">
        <div className="event">
          <div className="event--date">
            <span className="card-date-month">{month}</span>
            <span className="card-date-day">{day}</span>
          </div>
          <div>
            <div className="content event--info">
              {item?.head_title && (
                <div className="headline">{item.head_title}</div>
              )}
              <span className="tag">
                {month} {day}, {year} {time}
              </span>
              <h3>{item?.title}</h3>
              {!item.hide_description && <p>{truncatedDescription}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EventsGrid = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
  headline = '',
  description = '',
  ...props
}) => {
  const [viewMode, setViewMode] = useState('grid'); // State to toggle views
  // Extract location data from events
  const eventOnMap = items
    .map((item) => ({
      name: item.title,
      description: item.description,
      city: item.location,
      startDate: item.start,
      linkToEvent: item['@id'],
    }))
    .filter((event) => event.coordinates !== null);

  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';
  const allEventsLinkHref = props?.allEventsLinkHref?.[0]?.['@id'];
  const eventListingDescription = props?.eventListingDescription;

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="grid-events">
        <h2>{headline}</h2>
        <p>{description}</p>
        <div className="grid-events-header">
          <h3 className="grid-events-header-headline">
            {props?.headline || ''}
          </h3>
          <p className="grid-events-header-description">
            {eventListingDescription}
          </p>
          <button
            className="view-map-button"
            onClick={() => setViewMode(viewMode === 'grid' ? 'map' : 'grid')}
          >
            {viewMode === 'grid' ? (
              <img src={iconMap} alt="Map Icon" className="button-icon" />
            ) : (
              <img src={iconList} alt="List Icon" className="button-icon" />
            )}
            {viewMode === 'grid' ? 'View as map' : 'View as list'}
          </button>

          <div className="divider-line"></div>
        </div>

        {viewMode === 'grid' ? (
          <div className="items">
            {items.map((item) => {
              return (
                <div className="listing-item card" key={item['@id']}>
                  <ConditionalLink item={item} condition={!isEditMode}>
                    <ItemBodyTemplate item={item} />
                  </ConditionalLink>
                </div>
              );
            })}
          </div>
        ) : (
          <EventsMapView events={eventOnMap} />
        )}

        {allEventsLinkHref && (
          <Link
            to={flattenToAppURL(allEventsLinkHref)}
            href={flattenToAppURL(allEventsLinkHref)}
          >
            <div className="footer-link">VIEW ALL EVENTS</div>
          </Link>
        )}

        {link && <div className="footer">{link}</div>}
      </div>
    </>
  );
};

EventsGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default EventsGrid;
