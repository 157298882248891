import React from 'react';

const ArrowDownSvg = () => {
  return (
    <div className="arrow-down-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        className="arrow-down"
      >
        <path d="M22.782 13.8 17 19.582 11.218 13.8a1 1 0 0 0-1.414 1.414L16.29 21.7a.992.992 0 0 0 .71.292.997.997 0 0 0 .71-.292l6.486-6.486a1 1 0 0 0-1.414-1.414z"></path>
      </svg>
    </div>
  );
};

export default ArrowDownSvg;
